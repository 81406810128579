import React, {ReactElement} from 'react';
import { observer } from 'mobx-react-lite';
import {Button} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import TelegramIcon from '@material-ui/icons/Telegram';
import megapolisImg from '../images/logo-im.png';
import pravilnoBystroImg from '../images/logo-kr.png';
import potencialImg from '../images/potencial.png';
import securityImg from '../images/security-logo.png';
import styles from './styles.scss';
import {LOGIN_URL} from "../../../../constants/api";

const LandingPageNewComp = (): ReactElement => {
    const projects = [
        {
            title: 'Потенциал',
            description: 'Независимая оценка качества подготовки обучающихся 11-х классов, освоивших программу предпрофессионального образования',
            logo: potencialImg,
            extraLink: 'https://im.mcko.ru/potential.php'
        },
        {
            title: 'Информационная безопасность',
            description: 'Портал, посвящённый вопросам информационной безопасности и персональных данных',
            logo: securityImg,
            extraLink: 'https://security.mcko.ru'
        },
        {
            title: 'Правильно и быстро',
            description: 'Московский конкурс по скоростному набору текста',
            logo: pravilnoBystroImg,
            extraLink: 'https://im.mcko.ru/key/'
        }
    ]

    const openLinkInAnotherWindow = (url: string) => {
        window.open(url, '_blank');
    }

    return (
        <div className={styles.contentWrapper}>
            <div className={styles.content}>
                <img src={megapolisImg}/>
                <div className={styles.title}>
                    Система оценочных мероприятий по комплексной
                    <br/>
                    диагностике качества подготовки школьников
                </div>
                <div className={styles.subTitle}>
                    Проверка теоретических знаний и практических умений
                    <br/>
                    по программам общего образования
                </div>
                <div className={styles.buttonWrapper}>
                    <Button
                        href={LOGIN_URL}
                        classes={{ root: styles.buttonOverride }}
                        variant="contained"
                        size="large"
                    >
                        Войти
                    </Button>
                </div>
                <div className={styles.projectsWrapper}>
                    {projects.map((project, idx) => (
                        <div
                            className={styles.project}
                            key={project.extraLink}
                            onClick={() => openLinkInAnotherWindow(project.extraLink)}
                        >
                            <div className={styles.centerAlign}>
                                <div className={styles.projectBlockWrapper}>
                                    <div className={styles.projectBlock}>
                                        <img src={project.logo}/>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.projectTitle}>
                                {project.title}
                            </div>
                            <div className={styles.centerAlign}>
                                <div className={styles.projectDescription}>
                                    {project.description}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerColumns}>
                    <div className={styles.links}>
                        <div className={styles.link}>
                            <PhoneIcon/>
                            <a href="tel:+74994445721" target="_blank" rel="noreferrer">+7(499) 444-57-21</a>
                        </div>
                        <div className={styles.link}>
                            <EmailIcon/>
                            <a href="mailto:help@mcko.ru" target="_blank" rel="noreferrer">help@mcko.ru</a>
                        </div>
                        <div className={styles.link}>
                            <TelegramIcon/>
                            <a href="https://t.me/MCKO_IM" target="_blank" rel="noreferrer">t.me/MCKO_IM</a>
                        </div>
                    </div>
                    <div className={styles.sources}>
                        <div className={styles.sourceTitle}>
                            Дополнительные ресурсы:
                        </div>
                        <div className={styles.source}>
                            <a href="https://www.mos.ru/donm/" target="_blank" rel="noreferrer">
                                Департамент образования и науки города Москвы
                            </a>
                        </div>
                        <div className={styles.source}>
                            <a href="https://mcko.ru" target="_blank" rel="noreferrer">
                                Московский центр качества образования
                            </a>
                        </div>
                        <div className={styles.source}>
                            <a href="https://profil.mos.ru" target="_blank" rel="noreferrer">
                                Предпрофессиональное образование
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LandingPageNew = observer(LandingPageNewComp);
