import { ReactElement } from 'react';
import { OperatorConfirm } from 'components/pages/OperatorConfirm';
import { LandingPage } from 'components/pages/LandingPage';
import { Reg } from 'components/pages/Reg';
import {
  CreateEventPageProvider,
  EventCreateGroupsProvider,
  EventManageGroupsProvider,
  EventResultsProvider,
  EventsFiltersProvider,
  OurStudentsProvider,
  ProfileProvider,
  RegisteredMembersProvider,
  RegistrationProvider,
  ResultEditProvider,
  PersonalDataProvider,
  CreateLocationProvider,
  DirectoriesProvider,
  CreateGlobalEventProvider,
  GlobalEventsFiltersProvider,
  StudentsPhotosProvider,
  MyEventsProvider,
  CombineEventsProvider,
  CreateCombineEventProvider,
} from 'store/providers';
import { LAZY_COMPONENTS } from '../../lazyPages'; // eslint-disable-line no-restricted-imports
import { RouteRoles, Routes } from './enums';


type LazyComponents = typeof LAZY_COMPONENTS;

interface RoutesComponentsItem {
  exact: boolean,
  Component: LazyComponents[keyof LazyComponents] | (() => ReactElement),
  Provider?: ({ children }: { children: ReactElement }) => ReactElement,
}

export const ROUTES_COMPONENTS: Record<Routes, RoutesComponentsItem> = {
  [Routes.createEvent]: {
    exact: true,
    Component: LAZY_COMPONENTS.CreateEventPage,
    Provider: CreateEventPageProvider,
  },
  [Routes.editEvent]: {
    exact: true,
    Component: LAZY_COMPONENTS.EditEvent,
    Provider: CreateEventPageProvider,
  },
  [Routes.registeredMembers]: {
    exact: true,
    Component: LAZY_COMPONENTS.RegisteredMembers,
    Provider: RegisteredMembersProvider,
  },
  [Routes.confirm]: {
    exact: true,
    Component: OperatorConfirm,
  },
  [Routes.landing]: {
    exact: true,
    Component: LandingPage,
  },
  [Routes.main]: {
    exact: true,
    Component: LAZY_COMPONENTS.Main,
  },
  [Routes.reg]: {
    exact: true,
    Component: Reg,
  },
  [Routes.withoutEmail]: {
    exact: true,
    Component: LAZY_COMPONENTS.WithoutEmail,
  },
  [Routes.events]: {
    exact: true,
    Component: LAZY_COMPONENTS.Events,
    Provider: EventsFiltersProvider,
  },
  [Routes.event]: {
    exact: true,
    Component: LAZY_COMPONENTS.Event,
  },
  [Routes.registration]: {
    exact: true,
    Component: LAZY_COMPONENTS.Registration,
    Provider: RegistrationProvider,
  },
  [Routes.registrationOperator]: {
    exact: true,
    Component: LAZY_COMPONENTS.RegistrationOfOperator,
  },
  [Routes.manageGroups]: {
    exact: true,
    Component: LAZY_COMPONENTS.EventManageGroups,
    Provider: EventManageGroupsProvider,
  },
  [Routes.createGroups]: {
    exact: true,
    Component: LAZY_COMPONENTS.EventCreateGroups,
    Provider: EventCreateGroupsProvider,
  },
  [Routes.students]: {
    exact: true,
    Component: LAZY_COMPONENTS.StudentsPage,
    Provider: OurStudentsProvider,
  },
  [Routes.studentsPhotos]: {
    exact: true,
    Component: LAZY_COMPONENTS.StudentsPhotos,
    Provider: StudentsPhotosProvider
  },
  [Routes.profile]: {
    exact: true,
    Component: LAZY_COMPONENTS.Profile,
    Provider: ProfileProvider,
  },
  [Routes.result]: {
    exact: true,
    Component: LAZY_COMPONENTS.ResultPage,
  },
  [Routes.results]: {
    exact: true,
    Component: LAZY_COMPONENTS.EventResults,
    Provider: EventResultsProvider,
  },
  [Routes.editResult]: {
    exact: true,
    Component: LAZY_COMPONENTS.ResultEditPage,
    Provider: ResultEditProvider,
  },
  [Routes.agreement]: {
    exact: true,
    Component: LAZY_COMPONENTS.CertificatesTable,
    Provider: PersonalDataProvider,
  },
  [Routes.check]: {
    exact: true,
    Component: LAZY_COMPONENTS.Check,
  },
  [Routes.createLocation]: {
    exact: true,
    Component: LAZY_COMPONENTS.CreateLocation,
    Provider: CreateLocationProvider,
  },
  [Routes.locations]: {
    exact: true,
    Component: LAZY_COMPONENTS.DirectoriesTable,
    Provider: DirectoriesProvider,
  },
  [Routes.createCombineEvent]: {
    exact: true,
    Component: LAZY_COMPONENTS.CreateCombineEvent,
    Provider: CreateCombineEventProvider,
  },
  [Routes.editCombineEvent]: {
    exact: true,
    Component: LAZY_COMPONENTS.CreateCombineEvent,
    Provider: CreateCombineEventProvider,
  },
  [Routes.combineEvents]: {
    exact: true,
    Component: LAZY_COMPONENTS.CombineEvents,
    Provider: CombineEventsProvider,
  },
  [Routes.createGlobalEvent]: {
    exact: true,
    Component: LAZY_COMPONENTS.CreateGlobalEvent,
    Provider: CreateGlobalEventProvider,
  },
  [Routes.editGlobalEvent]: {
    exact: true,
    Component: LAZY_COMPONENTS.EditGlobalEvent,
    Provider: CreateGlobalEventProvider,
  },
  [Routes.globalEvents]: {
    exact: true,
    Component: LAZY_COMPONENTS.GlobalEvents,
    Provider: GlobalEventsFiltersProvider,
  },
  [Routes.teams]: {
    exact: true,
    Component: LAZY_COMPONENTS.Teams,
  },
  [Routes.myEvents]: {
    exact: true,
    Component: LAZY_COMPONENTS.MyEvents,
    Provider: MyEventsProvider
  },
  [Routes.myEvent]: {
    exact: true,
    Component: LAZY_COMPONENTS.MyEvent
  }
};

const DEFAULT_ROLE = [
  Routes.landing,
  Routes.main,
  Routes.events,
  Routes.event,
  Routes.reg,
  Routes.check,
];

const WITHOUT_PROFILE = [
  ...DEFAULT_ROLE,
  Routes.registration,
  Routes.registrationOperator,
  Routes.withoutEmail,
];

const STUDENTS = [
  ...DEFAULT_ROLE,
  Routes.myEvents,
  Routes.myEvent,
  Routes.profile,
  Routes.result,
];

const PARENTS = [
  ...DEFAULT_ROLE,
  Routes.profile
];

const SCHOOL_AND_UNIVERSITY = [
  ...DEFAULT_ROLE,
  Routes.createEvent,
  Routes.registrationOperator,
  Routes.registeredMembers,
  Routes.confirm,
  Routes.editEvent,
  Routes.manageGroups,
  Routes.createGroups,
  Routes.students,
  Routes.studentsPhotos,
  Routes.profile,
  Routes.result,
  Routes.results,
  Routes.editResult,
  Routes.agreement,
  Routes.locations,
  Routes.createLocation,
  Routes.teams,
];

const OPERATOR = [
  ...SCHOOL_AND_UNIVERSITY,
  Routes.createGlobalEvent,
  Routes.editGlobalEvent,
  Routes.globalEvents,
  Routes.combineEvents,
  Routes.createCombineEvent,
  Routes.editCombineEvent
];

export const WITHOUT_NAVBAR = [
  Routes.landing,
  Routes.registeredMembers,
  Routes.createEvent,
  Routes.event,
  Routes.myEvent,
  Routes.manageGroups,
  Routes.createGroups,
  Routes.students,
  Routes.studentsPhotos,
  Routes.profile,
  Routes.result,
  Routes.results,
  Routes.editResult,
  Routes.agreement,
  Routes.check,
  Routes.createLocation,
  Routes.createGlobalEvent,
  Routes.createCombineEvent,
  Routes.editCombineEvent
];

export const ROUTES_BY_ROLE = {
  [RouteRoles.default]: DEFAULT_ROLE,
  [RouteRoles.withoutFullProfile]: WITHOUT_PROFILE,
  [RouteRoles.student]: STUDENTS,
  [RouteRoles.schoolAndUniversity]: SCHOOL_AND_UNIVERSITY,
  [RouteRoles.operator]: OPERATOR,
  [RouteRoles.testOperator]: [Routes.landing, Routes.main],
  [RouteRoles.parent]: PARENTS
};
